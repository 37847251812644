var errorMessages = {
  403: 'Użytkownik nie jest upoważniony do wykonania tej operacji',
  400: 'Operacja nie udała się, spróbuj ponownie',
  500: 'Problem z zapytaniem- proszę zgłosić problem programiście rozwijającemu projekt',
}

function handleError(error) {
  return error.message || error.status || errorMessages[error.code] || 'Nierozpoznany błąd'
}

export default handleError
