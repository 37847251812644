import APISettings from './auth.config.js'

import axios from 'axios'
import storageAdapter from 'src/helpers/storage.js'

const logoutEvent = 'logout'

const routePrefix = 'auth'
const instance = axios.create({
  baseURL: [APISettings.baseURL, routePrefix].join('/'),
})

const AuthAPI = {
  login: async (model) => {
    var response = await instance({
      method: 'POST',
      url: `/login`,
      data: model,
    })
    return response.data
  },
  logout: async (model) => {
    var response = await instance({
      method: 'POST',
      url: `/logout`,
      data: model,
      headers: {
        Authorization: `Bearer ${sessionService.getSession()}`,
      },
    })
    return response.data
  },
  refreshTokens: async (model) => {
    var response = await instance({
      method: 'POST',
      url: `/refreshtoken`,
      data: model,
    })
    return response.data
  },
  getVersion: async (model) => {
    var response = await instance({
      method: 'POST',
      url: `/version/`,
    })
    return response.data
  },
}

const sessionService = {
  getSession: () => {
    return storageAdapter.getItem('accessToken')
  },
  getRefresh: () => {
    return storageAdapter.getItem('refreshToken')
  },
  setSession: (accessToken) => {
    storageAdapter.setItem('accessToken', accessToken)
  },
  setRefresh: (refreshToken) => {
    storageAdapter.setItem('refreshToken', refreshToken)
  },
  logout: () => {
    storageAdapter.clear()
    const logOutEvent = new CustomEvent(logoutEvent)
    document.dispatchEvent(logOutEvent)
  },
}

export default AuthAPI

export { sessionService, logoutEvent }
