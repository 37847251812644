import { getConfigSetting } from '../helpers/config'

const APISettings = {
  baseURL:
    getConfigSetting('BASE_SERVICE_URL') ||
    process.env.BASE_SERVICE_URL ||
    'http://127.0.0.1:3001/api',
  // baseURL: process.env.BASE_SERVICE_URL || '/api',
}

export default APISettings
