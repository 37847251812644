import baseInstance from './api.config'
import handleError from '../helpers/errors'

const SystemSettingsService = {
  smtpSettings: async (model) => {
    var response = await baseInstance({
      method: 'GET',
      url: `/system/smtp/`,
    })
    return response.data
  },
  saveSMTP: async (model) => {
    var response = await baseInstance({
      method: 'POST',
      url: `/system/smtp/`,
      data: model,
    })
    if (response.data && response.data.code !== 200) {
      throw handleError(response.data)
    }
    return response.data
  },
  generalSettings: async (model) => {
    var response = await baseInstance({
      method: 'GET',
      url: `/system/generalSystemSettings/`,
    })
    return response.data
  },
  organizationSettingsSettings: async (model) => {
    var response = await baseInstance({
      method: 'POST',
      url: `/system/organizationsettingssettings/`,
    })
    return response.data
  },
  saveGeneral: async (model) => {
    var response = await baseInstance({
      method: 'POST',
      url: `/system/generalSystemSettings/`,
      // for uploading logo in one form with other system settings
      headers: { 'Content-Type': 'multipart/form-data' },
      data: model,
    })
    if (response.data && response.data.code !== 200) {
      throw handleError(response.data)
    }
    return response.data
  },
  deleteLogo: async (model) => {
    var response = await baseInstance({
      method: 'POST',
      url: `/system/deletelogo/`,
      data: model,
    })
    if (response.data && response.data.code !== 200) {
      throw handleError(response.data)
    }
    return response.data
  },
  plotSettings: async (model) => {
    var response = await baseInstance({
      method: 'GET',
      url: `/system/plotSystemSettings/`,
    })
    return response.data
  },
  savePlot: async (model) => {
    var response = await baseInstance({
      method: 'POST',
      url: `/system/plotSystemSettings/`,
      data: model,
    })
    if (response.data && response.data.code !== 200) {
      throw handleError(response.data)
    }
    return response.data
  },
}

export default SystemSettingsService
