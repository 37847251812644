import UserService from './services/user.service'

const adapsTabs = {
  generateTabs: async (link, licenseTabs) => {
    let tabs = [
      {
        name: 'Wskazania',
        path: '/adaps/projectIndicators',
        projectDropdown: true,
        phaseDropdown: false,
        // For future modification with ProjectIndicators
        // overrideDropdownsWithUser: true,
        sortOrder: 1,
      },
      {
        name: 'Mapy',
        path: '/adaps/maps',
        projectDropdown: true,
        phaseDropdown: true,
        sortOrder: 2,
      },
      {
        name: 'Przebiegi',
        path: '/adaps/plots',
        projectDropdown: true,
        phaseDropdown: true,
        sortOrder: 3,
      },
      {
        name: 'Raporty',
        path: '/adaps/reports',
        // projectDropdown: true,
        // phaseDropdown: true,
        sortOrder: 4,
      },
      {
        name: 'Zdarzenia',
        path: '/adaps/alarms',
        sortOrder: 5,
      },
    ]

    if (UserService.hasAdminAccess()) {
      tabs.push(
        ...[
          {
            name: 'Ustawienia',
            path: '/adaps/settings',
            sortOrder: UserService.hasRole('super-admin') ? 7 : 6,
          },
          {
            name: 'Urządzenia',
            path: '/adaps/settings/devices',
            parent: 'Ustawienia',
            projectDropdown: true,
            phaseDropdown: true,
            sortOrder: UserService.hasSuperAdminAccess() ? 10 : 7,
          },
          {
            name: 'Sensory',
            path: '/adaps/settings/sensors',
            parent: 'Ustawienia',
            projectDropdown: true,
            phaseDropdown: true,
            sortOrder: UserService.hasSuperAdminAccess() ? 11 : 8,
          },
          {
            name: 'Grupy Sensorów',
            path: '/adaps/settings/reportgroups',
            parent: 'Ustawienia',
            projectDropdown: false,
            phaseDropdown: false,
            sortOrder: UserService.hasSuperAdminAccess() ? 17 : 9,
          },
          {
            name: 'Licencja',
            path: '/adaps/license',
            projectDropdown: false,
            phaseDropdown: false,
            sortOrder: UserService.hasRole('super-admin') ? 16 : 10,
          },
        ],
      )
    }
    if (UserService.hasSuperAdminAccess()) {
      tabs.push(
        ...[
          {
            name: 'Logi',
            path: '/adaps/logs',
            projectDropdown: true,
            phaseDropdown: false,
            sortOrder: 6,
          },
          {
            name: 'Projekty',
            path: '/adaps/settings/projects',
            parent: 'Ustawienia',
            projectDropdown: false,
            phaseDropdown: false,
            sortOrder: 8,
          },
          {
            name: 'Etapy (Części)',
            path: '/adaps/settings/phases',
            parent: 'Ustawienia',
            projectDropdown: true,
            phaseDropdown: false,
            sortOrder: 9,
          },
          {
            name: 'Mierzone typy',
            path: '/adaps/settings/measuredvaluetypes',
            parent: 'Ustawienia',
            projectDropdown: false,
            phaseDropdown: false,
            sortOrder: 12,
          },
          {
            name: 'Mierzone jednostki',
            path: '/adaps/settings/measuredvalueunits',
            parent: 'Ustawienia',
            projectDropdown: false,
            phaseDropdown: false,
            sortOrder: 13,
          },
          {
            name: 'Klienci',
            path: '/adaps/settings/customers',
            parent: 'Ustawienia',
            projectDropdown: false,
            phaseDropdown: false,
            sortOrder: 14,
          },
          {
            name: 'System',
            path: '/adaps/settings/system',
            parent: 'Ustawienia',
            projectDropdown: false,
            phaseDropdown: false,
            sortOrder: 18,
          },
        ],
      )
    }

    tabs.push(
      ...[
        {
          name: 'Pomoc',
          path: link,
          sortOrder: 99,
          type: 'manual',
        },
      ],
    )

    // filtering in order to avoid duplication of tabs
    tabs = tabs.filter(
      (tab, index, self) =>
        index === self.findIndex((t) => t.path === tab.path && t.name === tab.name),
    )

    tabs.sort((a, b) => a.sortOrder - b.sortOrder)
    // finalTabs- taking into consideration, what in the license is
    const finalTabs = tabs.filter(
      (le) => licenseTabs.includes(le.name) || licenseTabs.includes(le.parent),
    )

    return finalTabs
  },
}

export default adapsTabs
