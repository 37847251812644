import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'

import { Redirect, Route } from 'react-router-dom'
import { sessionService } from '../services/auth.service'
import UserService from '../services/user.service'
import AppSpinner from '../components/AppSpinner'
import Routes from '../routes'

class PrivateRoute extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      accessToken: sessionService.getSession(),
      validationError: false,
      isLoading: true,
    }

    // better to change for an event later on
    this.interval = setInterval(() => {
      if (this.state.accessToken !== sessionService.getSession()) {
        this.setState({
          accessToken: sessionService.getSession(),
        })
      }
    }, 50)
  }

  render() {
    return this.state.isLoading ? (
      <AppSpinner />
    ) : (
      <Route
        {...this.props.rest}
        render={({ location }) =>
          this.state.accessToken && !this.state.validationError ? (
            this.props.children
          ) : (
            <Redirect
              to={{
                pathname: '/adapslogin',
                state: { from: location },
              }}
            />
          )
        }
      />
    )
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  checkRoute = async (prevLocation) => {
    // Try to find based on main path name
    var foundRoute = Routes.routes.find((x) => x.path === this.props.location.pathname)

    if (!foundRoute) {
      // If main Path isn't found. Split pathName removing last / section to get the parent route.
      // Try and find it.
      var lastSlashIndex = this.props.location.pathname.lastIndexOf('/')
      var cutPath = this.props.location.pathname.substring(0, lastSlashIndex)
      var tempFoundRoute = Routes.routes.find((x) => x.path === cutPath)

      // If found parent route, check for the child route matching the last / of the url
      if (tempFoundRoute && tempFoundRoute?.data?.childRoutes) {
        var childRoute = this.props.location.pathname.substring(lastSlashIndex + 1)
        // If found use that to compare.
        foundRoute = tempFoundRoute?.data?.childRoutes.find((x) => x.route === childRoute)
      }
    }

    if (foundRoute) {
      var key = foundRoute?.data?.key
      var subKey = foundRoute?.data?.subKey

      if (key) {
        const validatedUserOrgResult = await UserService.validationUserOrg({
          key,
          subKey,
        })
        if (validatedUserOrgResult.code === 200 && validatedUserOrgResult.data.path !== null) {
          this.props.location.pathname = validatedUserOrgResult.data.path
        } else if (
          validatedUserOrgResult.code !== 200 &&
          !!prevLocation &&
          prevLocation.pathname !== this.props.location.pathname
        ) {
          this.props.history.push(prevLocation.pathname)
        } else if (validatedUserOrgResult.code !== 200) {
          this.setState({ validationError: true })
        }
      }
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({ isLoading: true })
      await this.checkRoute(prevProps.location)
      this.setState({ isLoading: false })
    }
  }

  async componentDidMount() {
    this.setState({ isLoading: true })
    await this.checkRoute()
    this.setState({ isLoading: false })
  }
}

PrivateRoute.propTypes = {
  rest: PropTypes.node,
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withRouter(PrivateRoute)
