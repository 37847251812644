const useLocalStorage = false

const storageAdapter = {
  getItem: (key) => {
    if (useLocalStorage) {
      return localStorage.getItem(key)
    } else {
      return sessionStorage.getItem(key)
    }
  },
  setItem: (key, value) => {
    if (useLocalStorage) {
      return localStorage.setItem(key, value)
    } else {
      return sessionStorage.setItem(key, value)
    }
  },
  removeItem: (key) => {
    if (useLocalStorage) {
      return localStorage.removeItem(key)
    } else {
      return sessionStorage.removeItem(key)
    }
  },
  clear: () => {
    if (useLocalStorage) {
      localStorage.clear()
    } else {
      sessionStorage.clear()
    }
  },
  getStorage: () => {
    if (useLocalStorage) {
      return localStorage
    } else {
      return sessionStorage
    }
  },
  // other methods with localStorage
}

export default storageAdapter
