import baseInstance from './api.config'
import jwt_decode from 'jwt-decode'
import handleError from '../helpers/errors'
import storageAdapter from '../helpers/storage'

const GetLicenseAPI = {
  _getSession: function () {
    return storageAdapter.getItem('accessToken')
  },
  getDecodedToken: function () {
    return jwt_decode(this._getSession())
  },
  getOrganizationId: function (name) {
    return this.getDecodedToken().organizationId || 0
  },
  refreshOrganizationDetails: async function () {
    return await this.license(this.getOrganizationId())
      .then((res) => {
        //Change to specific model
        return {
          orgDetails: res.organizationDetails,
          licenseLogo: res.licenseDetails.customLogo,
          licenseTabs: res.licenseDetails.tabs,
        }
      })
      .catch((error) => {
        // console.log(error)
      })
  },
  license: async (model) => {
    var response = await baseInstance({
      method: 'GET',
      url: `/license/get/` + model,
      data: model,
    })
    return response.data
  },
  updateLicenseOrg: async (model) => {
    var response = await baseInstance({
      method: 'POST',
      url: `/license/updatelicenseorg/`,
      data: model,
    })
    if (response.data && response.data.code !== 200) {
      throw handleError(response.data)
    }
    return response.data
  },
  uploadLicense: async (model) => {
    var response = await baseInstance({
      method: 'POST',
      url: `/license/uploadlicense/`,
      data: model,
    })

    return response.data
  },
  generateLicense: async (model) => {
    var response = await baseInstance({
      method: 'POST',
      url: `/license/generatelicense/`,
      data: model,
    })

    return response.data
  },
}

export default GetLicenseAPI
