import Spinner from 'react-bootstrap/Spinner'
import PropTypes from 'prop-types'

const AppSpinner = (props) => {
  return (
    <div className={`spinner-container ${!!props.className ? props.className : ''}`}>
      <Spinner animation="grow" />
    </div>
  )
}

AppSpinner.propTypes = {
  className: PropTypes.string,
}

export default AppSpinner
