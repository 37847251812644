import jwt_decode from 'jwt-decode'
import baseInstance from './api.config'
import handleError from '../helpers/errors'
import storageAdapter from '../helpers/storage'

const UserService = {
  usersLimit: async (pagination) => {
    var response = await baseInstance({
      method: 'POST',
      url: `/users/getwithlimit/`,
      headers: {
        pagination: JSON.stringify(pagination),
      },
    })
    return response.data
  },
  _getSession: function () {
    return storageAdapter.getItem('accessToken')
  },
  getDecodedToken: function () {
    if (!this._getSession()) {
      return
    }
    return jwt_decode(this._getSession())
  },
  getRoles: function () {
    return this.getDecodedToken().roles || []
  },
  hasRole: function (name) {
    return this.getRoles().indexOf(name) > -1
  },
  hasArchitectAccess: function () {
    return this.hasRole('architect')
  },
  hasSuperAdminAccess: function () {
    return this.hasRole('super-admin') || this.hasArchitectAccess()
  },
  hasAdminAccess: function () {
    return this.hasRole('admin') || this.hasSuperAdminAccess()
  },
  getId: function () {
    return this.getDecodedToken().id || []
  },
  getEmail: function () {
    return this.getDecodedToken().email || []
  },
  getOrganization: function () {
    return {
      organizationName: this.getDecodedToken().organizationName || [],
      organizationId: this.getDecodedToken().organizationId || [],
    }
  },
  user: async (model) => {
    var response = await baseInstance({
      method: 'GET',
      url: `/users/get/` + model,
      data: model,
    })
    if (response.data && response.data.code !== 200) {
      throw handleError(response.data)
    }
    return response.data
  },
  users: async (model) => {
    var response = await baseInstance({
      method: 'POST',
      url: `/users/get/`,
      data: model,
    })
    if (response.data && response.data.code !== 200) {
      throw handleError(response.data)
    }
    return response.data
  },
  createUser: async (model) => {
    var response = await baseInstance({
      method: 'POST',
      url: `/users/add/`,
      data: model,
    })
    if (response.data && response.data.code !== 200) {
      throw handleError(response.data)
    }
    return response.data
  },
  updateUserOrg: async (model) => {
    var response = await baseInstance({
      method: 'POST',
      url: `/users/updateuserorg/`,
      data: model,
    })
    if (response.data && response.data.code !== 200) {
      throw handleError(response.data)
    }
    return response.data
  },
  changePassword: async (model) => {
    var response = await baseInstance({
      method: 'POST',
      url: `/users/changepassword/`,
      data: model,
    })
    if (response.data && response.data.code !== 200) {
      throw handleError(response.data)
    }
    return response.data
  },
  updateOneField: async (model) => {
    var response = await baseInstance({
      method: 'POST',
      url: `/users/updateonefield/`,
      data: model,
    })
    if (response.data && response.data.code !== 200) {
      throw handleError(response.data)
    }
    return response.data
  },
  checkUsername: async (model) => {
    var response = await baseInstance({
      method: 'POST',
      url: `/users/checkusername/`,
      data: model,
    })
    if (response.data && response.data.code !== 200) {
      throw handleError(response.data)
    }
    return response.data
  },
  projects: async (model) => {
    var response = await baseInstance({
      method: 'GET',
      url: `/users/getprojects`,
      data: model,
    })
    return response.data
  },
  projectPhasesForUser: async (model) => {
    var response = await baseInstance({
      method: 'POST',
      url: `/users/getprojects/`,
      data: model,
    })
    return response.data
  },
  deleteUser: async (model) => {
    var response = await baseInstance({
      method: 'POST',
      url: `/users/deleteuser/`,
      data: model,
    })
    return response.data
  },
  alarmsAndWarnings: async (model) => {
    var response = await baseInstance({
      method: 'GET',
      url: `/users/getalarmsandwarnings`,
      data: model,
    })
    return response.data
  },
  organizationUsers: async (model) => {
    var response = await baseInstance({
      method: 'POST',
      url: `/users/organizationusers/`,
      data: model,
    })
    if (response.data && response.data.code !== 200) {
      throw handleError(response.data)
    }
    return response.data
  },
  reportGroupsForUser: async (model) => {
    var response = await baseInstance({
      method: 'POST',
      url: `/users/getreportgroupsforuser/`,
      data: model,
    })
    return response.data
  },
  validationUserOrg: async (model) => {
    var response = await baseInstance({
      method: 'POST',
      url: `/users/validationuserorg/`,
      data: model,
    })
    return response.data
  },
}

export default UserService
