import React, { Component } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import moment from 'moment'

import PrivateRoute from './utils/PrivateRoute'
import './scss/style.scss'
import { systemSettingsEvent } from './components/adaps/Settings/SystemGeneral'
import AuthAPI, { sessionService, logoutEvent } from './services/auth.service'
import LicenseService from './services/license.service'
import AppSpinner from './components/AppSpinner'
import adapsTabs from './adapsTabs'
import UserService from './services/user.service'
import storageAdapter from './helpers/storage'

import { EventNames } from './constants/events'

// Containers
const ADAPSLayout = React.lazy(() => import('./layout/ADAPSLayout'))

// Pages
const ADAPSLogin = React.lazy(() => import('./views/pages/adapsLogin/ADAPSLogin'))

class App extends Component {
  dateFormat = 'HH:mm:ss DD-MM-YYYY'
  alarmInterval = null
  dateAndTimeInterval = null
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      tabs: [],
      alarmsWarnings: {},
      fullScreen: false,
      dateAndTime: moment(new Date()).format(this.dateFormat),
    }
  }

  systemSettingsChanged = async () => {
    let orgDetails = await LicenseService.refreshOrganizationDetails()
    const tabs = await adapsTabs.generateTabs(orgDetails.orgDetails.helpUrl, orgDetails.licenseTabs)
    this.setState({ orgDetails, tabs })
  }

  onFullScreenChange = async (e) => {
    const fullscreenElement =
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement ||
      document.webkitCurrentFullScreenElement ||
      document.webkitIsFullScreen

    if (!!fullscreenElement !== this.state.fullScreen) {
      this.setState(
        {
          fullScreen: !!fullscreenElement,
        },
        () => {
          this.dispatchFullScreenCompletionChange()
        },
      )
    }
  }

  handleFullScreenRequest = async (e) => {
    this.setState(
      {
        fullScreen: e.detail?.entering,
      },
      () => {
        this.dispatchFullScreenCompletionChange()

        if (!e.detail?.entering) {
          document.exitFullscreen()
        }
      },
    )
  }

  dispatchFullScreenCompletionChange = () => {
    var isOpen = this.state.fullScreen

    const fullScreenCompletedEvent = new CustomEvent(EventNames.FullScreenChangeCompleted, {
      detail: {
        isOpen: isOpen,
      },
    })
    document.dispatchEvent(fullScreenCompletedEvent)
  }

  onLogin = async () => {
    this.setState({ isLoading: true })
    try {
      let orgDetails = await LicenseService.refreshOrganizationDetails()
      const tabs = await adapsTabs.generateTabs(
        orgDetails.orgDetails.helpUrl,
        orgDetails.licenseTabs,
      )

      //Setup interval
      return UserService.alarmsAndWarnings().then((res) => {
        const alarmsWarnings = res
        let dateAndTime = moment(new Date()).format(this.dateFormat)
        this.setState({ alarmsWarnings, tabs, isLoading: false, orgDetails, dateAndTime })
        if (this.alarmInterval) clearInterval(this.alarmInterval)
        this.alarmInterval = setInterval((x) => {
          return UserService.alarmsAndWarnings().then((res) => {
            const alarmsWarnings = res
            this.setState({ alarmsWarnings })
          })
        }, 5000)
        if (this.dateAndTimeInterval) clearInterval(this.dateAndTimeInterval)
        this.dateAndTimeInterval = setInterval((x) => {
          let dateAndTime = moment(new Date()).format(this.dateFormat)
          this.setState({ dateAndTime })
        }, 1000)
      })
    } catch (e) {
      this.setState({ isLoading: false, tabs: [] })
    }
  }

  onLogoutClearInterval = () => {
    if (this.alarmInterval) clearInterval(this.alarmInterval)
    if (this.dateAndTimeInterval) clearInterval(this.dateAndTimeInterval)
  }

  componentDidMount() {
    document.addEventListener(logoutEvent, this.onLogoutClearInterval)
    document.addEventListener(systemSettingsEvent, this.systemSettingsChanged)
    document.addEventListener('fullscreenchange', this.onFullScreenChange)
    document.addEventListener(EventNames.FullScreenChange, this.handleFullScreenRequest)

    // if a user is logged in, there is a token
    let tabs, orgDetails, dateAndTime
    if (sessionService.getSession()) {
      return AuthAPI.refreshTokens({
        refreshToken: sessionService.getRefresh(),
      })
        .then(async (res) => {
          sessionService.setSession(res.accessToken)
          orgDetails = await LicenseService.refreshOrganizationDetails()
          tabs = await adapsTabs.generateTabs(orgDetails.orgDetails.helpUrl, orgDetails.licenseTabs)
          dateAndTime = moment(new Date()).format(this.dateFormat)

          return UserService.alarmsAndWarnings()
        })
        .then((res) => {
          const alarmsWarnings = res
          this.setState({ alarmsWarnings, tabs, orgDetails, dateAndTime })
          if (!this.alarmInterval) {
            this.alarmInterval = setInterval((x) => {
              return UserService.alarmsAndWarnings().then((res) => {
                const alarmsWarnings = res
                this.setState({ alarmsWarnings })
              })
            }, 5000)
          }
          if (!this.dateAndTimeInterval) {
            this.dateAndTimeInterval = setInterval((x) => {
              dateAndTime = moment(new Date()).format(this.dateFormat)
              this.setState({ dateAndTime })
            }, 1000)
          }
        })
        .catch((error) => {
          storageAdapter.clear()
          this.setState({ tabs: [] })
          return Promise.reject(error)
        })
        .finally(() => {
          this.setState({ isLoading: false })
        })
    } else {
      this.setState({ isLoading: false })
      storageAdapter.clear()
    }
  }

  // is not reached, to be fixed
  componentWillUnmount() {
    document.removeEventListener(logoutEvent, this.onLogoutClearInterval)
    document.removeEventListener(systemSettingsEvent, this.systemSettingsChanged)
    document.removeEventListener('fullscreenchange', this.onFullScreenChange)
    document.removeEventListener(EventNames.FullScreenChange, this.handleFullScreenRequest)
  }

  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={<AppSpinner />}>
          <Switch>
            <Route
              exact
              path="/adapslogin"
              name="ADAPS Login Page"
              render={(props) => <ADAPSLogin {...props} onLogin={this.onLogin} />}
            />
            <PrivateRoute path="/">
              <Route
                path="/"
                name="Home"
                render={(props) => (
                  <ADAPSLayout
                    {...props}
                    tabs={this.state.tabs}
                    alarmsWarnings={this.state.alarmsWarnings}
                    dateAndTime={this.state.dateAndTime}
                    orgDetails={this.state.orgDetails}
                    isLoading={this.state.isLoading}
                    className={this.state.fullScreen ? 'full-screen' : ''}
                  />
                )}
              />
            </PrivateRoute>
          </Switch>
        </React.Suspense>
      </HashRouter>
    )
  }
}

export default App

// for debuggin purpioses- instead of HashRouter
// class DebugRouter extends HashRouter {
//   constructor(props) {
//     super(props)
//     console.log('initial history is: ', JSON.stringify(this.history, null, 2))
//     this.history.listen((location, action) => {
//       console.log(`The current URL is ${location.pathname}${location.search}${location.hash}`)
//       console.log(`The last navigation action was ${action}`, JSON.stringify(this.history, null, 2))
//       console.log(this)
//     })
//   }
// }
